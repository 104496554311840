import { useMemo, useState, useEffect, useRef } from 'react'
import * as THREE from 'three'
import ModelLoader, { TTeethStepsPosition } from "./ModelLoader/ModelLoader"
import { TModelData, TSteppedModelData, TTeethModelData, TViewValue } from './types'
import Preloader from './Ui/Preloader'
import { mergeBufferGeometries } from './ModelLoader/BufferGeometryUtils'
import getZoomValue from './Utils/get-viewport-zoom-value'
import useInterval from './Utils/use-interval'
import View3dTimeline from './Ui/View3dTimeline'
import View3dBeforeAfter from './Ui/View3dBeforeAfter'
import ZipFileLoader from './Ui/ZipFileLoader'
import ModelFromFromGeometry from './Scene/ModelFromFromGeometry'
import { ResizeObserver as Polyfill } from '@juggle/resize-observer'

export const delayValue = 700

const ResizeObserver = window.ResizeObserver || Polyfill
// Uses native or polyfill, depending on browser support.
new ResizeObserver((entries, observer) => {})

export type TViewBeforeAfter = "" | "BEFORE" | "AFTER"
const getTransformedGeometry = ( transformation: TTeethStepsPosition[], geometryData: TModelData[] ) => {
    const transformedGeometry:THREE.BufferGeometry[] = []

    transformation.forEach( (transformationItem) =>{
                
        const filteredModel = geometryData
            .filter( dataItem => dataItem.name.slice(-6).indexOf( transformationItem.id ) > -1)

        if(filteredModel.length === 1){
            const currentModel = filteredModel[0]
            const modelGeometryData =  currentModel.data.clone()
            const rotationMatrix =  transformationItem.rotationMatrix.clone()
            

            const tMatrix4 = new THREE.Matrix4()
            const rmtx = rotationMatrix.transpose().toArray()
            const tVec = transformationItem.position
            tMatrix4.elements = [
                rmtx[0], rmtx[1], rmtx[2], 0,
                rmtx[3], rmtx[4], rmtx[5], 0,
                rmtx[6], rmtx[7], rmtx[8], 0,
                tVec.x , tVec.y , tVec.z , 1
            ]
            modelGeometryData.applyMatrix4(tMatrix4)
            transformedGeometry.push(modelGeometryData)
        }                    
    })


    return ( mergeBufferGeometries(transformedGeometry))
} 

export type TApplicationMode = 'CASE_FROM_URL_LOADING' | 'CASE_FROM_URL_DONE' | 'CASE_FROM_LOCAL_FILE' | 'CASE_FROM_LOCAL_FILE_PARSING' | 'CASE_FROM_LOCAL_FILE_DONE' 
export type TApplicationActiveTab = 'TIMELINE' | 'BEFORE_AFTER'

const JsTpviewer = () => {
    
    const parsedUrl = new URL(window.location.href).searchParams.get('case')
    const url = parsedUrl !== null ?  parsedUrl : ''
    const [ activeTab, setActiveTab] = useState<TApplicationActiveTab>('TIMELINE')
    const viewForAutoPlay:TViewValue[] = ['front', 'top']
    const [ viewIndex                    , setViewIndex                    ] = useState(0)
    const [ isPreloaderVisible           , setPreloaderVisible             ] = useState(true)
    const [ applicationMode              , setApplicationMode              ] = useState<TApplicationMode>( url ? 'CASE_FROM_URL_LOADING' : 'CASE_FROM_LOCAL_FILE')
    const [ stepIndex                    , setStepIndex                    ] = useState(0)
    const [ isPlayed                     , setIsPlayed                     ] = useState(false) 
    const [ currentView                  , setCurrentView                  ] = useState<TViewValue>('front')

    const [ isViewClicked                , setViewClicked                  ] = useState(false) // disable autochange of view  if user change view

    const [ teethModelStepTransformation , setTeethModelStepTransformation ] = useState<TTeethStepsPosition[][]>([])
    const [ delay, setDelay] = useState<null|number>(null) // interval size

    const [                              , setMouseMove                    ] = useState(false)
    
    const cameraPosition = useRef({
        position: new THREE.Vector3(0,0,100),
        zoom: getZoomValue(),
    })

    const setCameraParameters = (x:number, y:number, z:number, zoom: number) =>{
        cameraPosition.current = {
            position: new THREE.Vector3(x,y,z),
            zoom
        }
    }
    const [ pauseDelay, setPauseDelay] = useState<null|number>(null) // interval size
    // teeths have base geometry for all steps, but transformed for every step
    const [ teethModelData, setTeethModelData ] = useState<TTeethModelData>({
        upper: [],
        lower: [],
    })

    const [ teethModelGeometry, setTeethModelGeometry ] = useState<TSteppedModelData>({
        upperSteps: [],
        lowerSteps: []
    })

    // gingiva have own geometry for every step
    const [ gingivaModelGeometry, setGingivaModeGeometry ] = useState<TSteppedModelData>({
        upperSteps: [],
        lowerSteps: []
    })

    const [smilewrapperInfo, setSmilewrapperInfo         ] = useState<string | undefined>()


    useEffect(()=>{
        const teethsteppedGeometry:TSteppedModelData = {
            upperSteps: [],
            lowerSteps: []
        }

        if(teethModelStepTransformation.length > 0){
            
            teethModelStepTransformation.forEach((stepTransformation, stepIndex)=>{
                
                teethsteppedGeometry.upperSteps.push({
                    name: `teeth-stage${stepIndex}-upper`,
                    data: getTransformedGeometry( stepTransformation,teethModelData.upper )
                })
                
                teethsteppedGeometry.lowerSteps.push({
                    name: `teeth-stage${stepIndex}-lower`,
                    data: getTransformedGeometry( stepTransformation,teethModelData.lower )
                })

            })

            setTeethModelGeometry(teethsteppedGeometry)
        }

        return(()=>{
            teethsteppedGeometry.lowerSteps = []
            teethsteppedGeometry.upperSteps = []
        })
    },[teethModelData, teethModelStepTransformation])
    

    // ANIMATION START
    useInterval(()=>{
        if(isPlayed === true && stepIndex + 1 < gingivaModelGeometry.upperSteps.length){
            setStepIndex (stepIndex + 1)
        }else{
            if(viewIndex>-1 && isPlayed === true && stepIndex === gingivaModelGeometry.upperSteps.length-1 ){
                if(viewIndex < viewForAutoPlay.length-1){
                    setPauseDelay(2000)
                    setDelay(null)
                }else{
                    setDelay(null)
                    setIsPlayed(false)
                }
            }else{
                setDelay(null)
                setIsPlayed(false)
            }
        }
    }, delay)

    useInterval(()=>{
        setViewIndex(viewIndex+1)
        setPauseDelay(null)
    }, pauseDelay)

    useEffect(()=>{
        if(viewIndex>-1 && viewIndex <= viewForAutoPlay.length-1){
            if(isViewClicked === false){
                setCurrentView(viewForAutoPlay[viewIndex])
                setStepIndex(0)
                setDelay(delayValue)
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[viewIndex, isViewClicked])
    // ANIMATION END

    const cancelStartupAnimation = () =>{
        setPauseDelay(null)
        setDelay(null)
        setIsPlayed(false)
        setViewIndex(-1)
    }


    
    const teethsModels = useMemo(()=>{ return( // FOR TIMELINE
        <ModelFromFromGeometry
            modelType='TEETHS'
            modelsGeometry={teethModelGeometry}
            stepIndex = {stepIndex}
        />
    )},[teethModelGeometry, stepIndex])

    const teethsModelsBefore = useMemo(()=>{ return(
        <ModelFromFromGeometry
            modelType='TEETHS'
            modelsGeometry={teethModelGeometry}
            stepIndex = {0}
        />
    )},[teethModelGeometry])


    const teethsModelsAfter = useMemo(()=>{return(
        <ModelFromFromGeometry
            modelType='TEETHS'
            modelsGeometry={teethModelGeometry}
            stepIndex = {teethModelGeometry.upperSteps.length-1 }
        />
    )},[teethModelGeometry])

    const gingivaModels = useMemo(()=>{ return(
        <ModelFromFromGeometry
            modelType='GINGIVA'
            modelsGeometry={gingivaModelGeometry}
            stepIndex = {stepIndex }
        />
    )},[gingivaModelGeometry, stepIndex])


    const gingivaModelsBefore = useMemo(()=>{return(
        <ModelFromFromGeometry
            modelType='GINGIVA'
            modelsGeometry={gingivaModelGeometry}
            stepIndex = { 0 }
        />
    )},[gingivaModelGeometry])

    const gingivaModelsAfter = useMemo(()=>{return(
        <ModelFromFromGeometry
            modelType='GINGIVA'
            modelsGeometry={gingivaModelGeometry}
            stepIndex = {gingivaModelGeometry.upperSteps.length-1 }
        />
    )},[gingivaModelGeometry])

    useEffect(()=>{

        if( isPreloaderVisible === false){
            setApplicationMode('CASE_FROM_URL_DONE')
        }
        if( applicationMode === 'CASE_FROM_LOCAL_FILE_DONE' || applicationMode === 'CASE_FROM_URL_DONE'){
            setDelay(delayValue)
            setIsPlayed(true)
        }
    },[applicationMode, isPreloaderVisible])

    // disable startup animation if before/after was enabled
    useEffect(()=>{
        if(isPlayed===true){
            cancelStartupAnimation()
        }
    // eslint-disable-next-line
    },[activeTab])

    return (
        <>
            
            {
                applicationMode === 'CASE_FROM_URL_LOADING' &&
                <>
                    <Preloader/>
                    <ModelLoader 
                        url                              = { url                             }
                        setTeethModelData                = { setTeethModelData               }
                        setGingivaModelData              = { setGingivaModeGeometry          }
                        setTeethModelGeometry            = { setTeethModelGeometry           }         
                        setTeethModelStepTransformation  = { setTeethModelStepTransformation }
                        setPreloaderVisible              = { setPreloaderVisible             }
                        setSmilewrapperInfo              = { setSmilewrapperInfo             }
                    />
                </>
            }

            {
                applicationMode === 'CASE_FROM_LOCAL_FILE_PARSING' &&
                <Preloader/>
            }

            {
                (applicationMode === 'CASE_FROM_LOCAL_FILE') && 
                <ZipFileLoader
                    setApplicationMode              = { setApplicationMode              }
                    setGingivaModeGeometry          = { setGingivaModeGeometry          }
                    setPreloaderVisible             = { setPreloaderVisible             }
                    setTeethModelData               = { setTeethModelData               }
                    setTeethModelStepTransformation = { setTeethModelStepTransformation }
                    setSmilewrapperInfo             = { setSmilewrapperInfo             }
                />
            }

            {
                ( applicationMode === 'CASE_FROM_LOCAL_FILE_DONE' || applicationMode === 'CASE_FROM_URL_DONE') && 
                <>  

                    {
                        activeTab==='TIMELINE' &&   
                            <View3dTimeline
                                gingivaModelGeometry   = { gingivaModelGeometry   }
                                gingivaModels          = { gingivaModels          }
                                teethsModels           = { teethsModels           }
                                currentView            = { currentView            } 
                                setCurrentView         = { setCurrentView         }
                                isViewClicked          = { isViewClicked          }
                                setViewClicked         = { setViewClicked         }
                                smilewrapperInfo       = { smilewrapperInfo       }
                                stepIndex              = { stepIndex              }
                                setStepIndex           = { setStepIndex           }
                                isPlayed               = { isPlayed               }
                                setIsPlayed            = { setIsPlayed            }
                                delay                  = { delay                  }
                                setDelay               = { setDelay               }
                                activeTab              = { activeTab              }
                                setActiveTab           = { setActiveTab           }
                                cancelStartupAnimation = { cancelStartupAnimation }
                            />
                    }


                    {
                        activeTab==='BEFORE_AFTER' &&
                            <View3dBeforeAfter
                                setCameraParameters      = { setCameraParameters      }
                                cameraPosition           = { cameraPosition           }
                                teethsModelsBefore       = { teethsModelsBefore       }
                                teethsModelsAfter        = { teethsModelsAfter        }
                                gingivaModelsBefore      = { gingivaModelsBefore      }
                                gingivaModelsAfter       = { gingivaModelsAfter       }
                                activeTab                = { activeTab                }
                                setActiveTab             = { setActiveTab             }
                                currentView              = { currentView              }
                                setCurrentView           = { setCurrentView           }
                                isViewClicked            = { isViewClicked            }
                                setViewClicked           = { setViewClicked           }
                                setStepIndex             = { setStepIndex             }
                            />
                    }

                </>
            }
            
            
        </>
    )
}

export default JsTpviewer
