import { Dispatch, SetStateAction } from 'react'
import { OrbitControls } from '@react-three/drei'
import { Canvas } from '@react-three/fiber'
import * as THREE from 'three'
import CameraLightController from '../Scene/CameraLightController'
import ViewTransformator from '../Scene/ViewTransformator'
import { TSteppedModelData, TViewValue } from '../types'
import getZoomValue from '../Utils/get-viewport-zoom-value'
import Information from './Information'
import ViewControls from './ViewControls'
import TimeLineControls from './TimeLineControls'
import SwitchBeforeAfter from './SwitchBeforeAfter'
import { TApplicationActiveTab } from '../JsTpviewer'
import { ResizeObserver } from '@juggle/resize-observer'

type TView3dTimelineProps = {
    teethsModels    : JSX.Element | undefined
    gingivaModels   : JSX.Element | undefined
    gingivaModelGeometry : TSteppedModelData
    currentView     : TViewValue
    setCurrentView  : Dispatch<SetStateAction<TViewValue>>
    
    isViewClicked   : boolean
    setViewClicked  : Dispatch<SetStateAction<boolean>>
    
    smilewrapperInfo: string | undefined

    stepIndex       : number
    setStepIndex    : Dispatch<SetStateAction<number>>
    
    isPlayed        : boolean
    setIsPlayed     : Dispatch<SetStateAction<boolean>>

    delay           : number | null
    setDelay        : Dispatch<SetStateAction<number | null>>

    cancelStartupAnimation : () => void

    activeTab : TApplicationActiveTab
    setActiveTab: Dispatch<SetStateAction<TApplicationActiveTab>>
}

const View3dTimeline = (props:TView3dTimelineProps ) =>{

    const {  
        gingivaModelGeometry,
        gingivaModels,
        teethsModels,
        currentView,
        setCurrentView,
        isViewClicked,
        setViewClicked,
        smilewrapperInfo,
        stepIndex,
        setStepIndex,
        isPlayed,
        setIsPlayed,
        delay,
        setDelay,
        activeTab,
        setActiveTab,
        cancelStartupAnimation,
    } = props

    return(
        <>
            <div id='view-3d'>
                
                

                <Canvas
                    resize={{ 
                        polyfill: ResizeObserver,
                        scroll: true, 
                        debounce: { 
                            scroll: 50, 
                            resize: 0 
                        }
                    }} 
                    gl={{ 
                        antialias: true,
                        autoClearColor: true,
                        toneMapping: THREE.NoToneMapping,
                        alpha: true,
                    }}
                    legacy
                    linear

                    camera={{
                        position: new THREE.Vector3(0,0,-10),
                        zoom: getZoomValue()
                    }}

                    orthographic
                > 
                    
                    <OrbitControls 
                        enablePan={false}
                        dampingFactor = { 0.65 }
                    />
                    
                    <directionalLight name='light'  color= { 0xffffff } intensity={ 1.0 } position={[0,0,10]} />
                    <CameraLightController/>

                    <hemisphereLight 
                        //args={['#888899', '#333344']} // skyColor={ 0x443333 } groundColor={ 0x111122 } intensity
                        args={['#161111', '#161111']}
                        intensity={1}
                    />

                    { teethsModels  }

                    { gingivaModels }


                    <ViewTransformator 
                        currentView    = { currentView }
                        isViewClicked  = { isViewClicked                         } 
                    />

                </Canvas>
                
                <img id='label-3d' src="3d.png" alt="3d"/>
                
                <Information smilewrapperInfo = { smilewrapperInfo }/>

            </div>
            
            <div id='controls'>

                <ViewControls 
                    currentView       = { currentView                            }
                    setCurrentView    = { setCurrentView                         }
                    setViewClicked    = { setViewClicked                         }
                />

                <TimeLineControls
                    
                    currentView       = { currentView                            }
                    setCurrentView    = { setCurrentView                         }  
                    setViewClicked    = { setViewClicked                         } 
                    setStepIndex      = { setStepIndex                           }
                    setIsPlayed       = { setIsPlayed                            }
                    isPlayed          = { isPlayed                               }
                    maxSteps          = { gingivaModelGeometry.upperSteps.length }
                    currentIndex      = { stepIndex                              }
                    delay             = { delay                                  }
                    setDelay          = { setDelay                               }
                    cancelAnimation   = { cancelStartupAnimation                 }
                />
                
                <SwitchBeforeAfter 
                    activeTab    = { activeTab    }
                    setActiveTab = { setActiveTab }
                    setStepIndex = { setStepIndex }
                />

            </div>
        </>
    )
}


export default View3dTimeline
