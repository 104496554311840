import * as THREE from 'three'
import { TModelData } from "../../types"

type TTeethsProps = {
    modelName: string
    modelData:TModelData[],
    opacity  ?: boolean
}


export const toothMaterial = new THREE.MeshPhongMaterial({
    color: 0xeeeeee,
    shininess: 10,
    specular: 0x111111,
    flatShading: true,

})
const Teeths = ( props:TTeethsProps ) => {
    
    const {
        modelData, 
        modelName,
    } = props

    return(
        <group
            name={ modelName }
        >
            {
                modelData.map((modelItem, modelIndex)=>{

                    const { name, data } = modelItem
                    let   newModelName        = name
                    const splittedName = name.split('_')

                    if(splittedName.length === 3){
                        const splittedFileName = splittedName[2].split('.')
                        newModelName = `teeth-${splittedFileName[0]}`
                    }

                    return(
                        <mesh
                            name     = { newModelName                           }
                            key      = {`${modelName}-teeth-item-${modelIndex}` }
                            geometry = { data                                   }
                            //material = { toothMaterial                    }
                        >
                            <meshPhongMaterial 
                                color       = { 0xeeeeee }
                                shininess   = { 10       }
                                specular    = { 0x111111 } 
                                flatShading = { true     }
                            />
                        </mesh>
                    )
                })
            }

        </group>
    )
}

export default Teeths
