import { mdiMargin } from "@mdi/js"
import JSZip from "jszip"
import { Dispatch, SetStateAction } from "react"
import { TApplicationMode } from "../JsTpviewer"
import { TTeethStepsPosition } from "../ModelLoader/ModelLoader"
import unzipSmilewrapper from "../ModelLoader/unzipSmilewrapper"
import { TSteppedModelData, TTeethModelData } from "../types"

type TZipFileLoaderProps = {
    setApplicationMode              : (value: React.SetStateAction<TApplicationMode>) => void
    setGingivaModeGeometry          : Dispatch<SetStateAction<TSteppedModelData>>
    setPreloaderVisible             : Dispatch<SetStateAction<boolean>>
    setTeethModelData               : Dispatch<SetStateAction<TTeethModelData>>
    setTeethModelStepTransformation : Dispatch<SetStateAction<TTeethStepsPosition[][]>>
    setSmilewrapperInfo             : Dispatch<SetStateAction<string | undefined>>
}

const ZipFileLoader = (props:TZipFileLoaderProps) => {
    const {
        setApplicationMode,
        setGingivaModeGeometry,
        setPreloaderVisible,
        setSmilewrapperInfo,
        setTeethModelData,
        setTeethModelStepTransformation,
    } = props
    return(
        <div id='file-loader'>
            <label className="button-load-file">
                Load 
                <input 
                    type="file"
                    accept="application/zip"
                    style={{display: 'none'}} 
                    required
                    onChange={(e)=>{
                        
                        if(e.target.files && e.target.files[0]){
                            setApplicationMode('CASE_FROM_LOCAL_FILE_PARSING')

                            const fileData:File = e.target.files[0]
                            const zip = new JSZip()
                            zip.loadAsync(fileData)
                            .then((fileContent:JSZip) => {
                                unzipSmilewrapper(
                                    fileContent,
                                    {
                                        url: '',
                                        setGingivaModelData: setGingivaModeGeometry,
                                        setPreloaderVisible,
                                        setTeethModelData,
                                        setTeethModelStepTransformation,
                                        setSmilewrapperInfo
                                    },
                                    ()=>{
                                        setApplicationMode('CASE_FROM_LOCAL_FILE_DONE')
                                    }
                                )
                            })
                            .catch((e)=>{
                                console.error("Can't unzip local file")
                            })
                        }
                    }}
                />
            </label>
        </div>
    )
}

export default ZipFileLoader
