import { useFrame, useThree } from "@react-three/fiber"
import * as THREE from 'three'
import { useEffect, useRef } from "react"
import { TViewValue } from "../types"
import getZoomValue from "../Utils/get-viewport-zoom-value"
import { TApplicationActiveTab } from "../JsTpviewer"

type TViewTransformatorProps = {
    activeTab?: TApplicationActiveTab  
    currentView: TViewValue
    isViewClicked: boolean
    setCameraParameters?: (x:number,y:number,z: number, zoom: number) => void
}

const setViewVisibility = (currentView: TViewValue, sceneObjects: THREE.Object3D[]) => {
    console.log(sceneObjects)
    sceneObjects.forEach( model => model.visible = true )

    switch(currentView){
        
        case "top":
            sceneObjects.forEach( (model) => {
                if(model.name.indexOf('lower')>-1){
                    model.visible = false
                }
                if(model.name.indexOf('-3')>-1 || model.name.indexOf('-4')>-1){
                    model.visible = false
                }
            } )
            break
        case "bottom":
            sceneObjects.forEach( (model) => {
                if(model.name.indexOf('upper')>-1){
                    model.visible = false
                }
                if(model.name.indexOf('-1')>-1 || model.name.indexOf('-2')>-1){
                    model.visible = false
                }
            } )
            break
        default:
            break
    }
}

const setModelsRotation = (currentView: TViewValue, sceneObjects: THREE.Object3D[]) =>{
    
    switch(currentView){
        case 'top':
            sceneObjects.forEach( (model) => {model.rotation.copy(new THREE.Euler(-Math.PI/2,0,0)) })
            break
        case 'bottom':
            sceneObjects.forEach( (model) => { model.rotation.copy(new THREE.Euler(Math.PI/2,0,0)) })
            break
        case 'left':
            sceneObjects.forEach( (model) => { model.rotation.copy(new THREE.Euler(0,-Math.PI/2,0)) })
            break
        case 'right':
            sceneObjects.forEach( (model) => { model.rotation.copy(new THREE.Euler(0,Math.PI/2,0))})
            break

        default:
            sceneObjects.forEach( model => model.rotation.copy(new THREE.Euler()))
            break
    }
}

export const cameraDistance = 500
const ViewTransformator = ( props: TViewTransformatorProps ) => {
    
    const { currentView, isViewClicked, activeTab, setCameraParameters } = props
    
    const { camera, scene } = useThree(state => state)
    const { children } = scene
    
    useEffect(()=>{
        isNeedUpdate.current = true
    },[currentView])


    const isNeedUpdate = useRef(true)

    useFrame(({ gl, scene, camera }) => {
        if(isNeedUpdate.current === true){

            camera.zoom = getZoomValue(activeTab)
            isNeedUpdate.current = false
            camera.position.copy(new THREE.Vector3(0,0,cameraDistance))
            camera.setRotationFromEuler(new THREE.Euler(0,0,0))
            setViewVisibility(currentView, children)
            setModelsRotation(currentView, children)
            camera.updateProjectionMatrix()
            gl.render(scene, camera)
        }
    })
    

    return(<></>)
}

export default ViewTransformator
