import { TSteppedModelData } from "../types"
import Gingiva from "./MeshGeometry/Gingiva"
import Teeths from "./MeshGeometry/Teeths"

type TModelFromFromGeometryProps = {
    modelsGeometry: TSteppedModelData
    modelType: 'TEETHS' | 'GINGIVA'
    stepIndex: number
}

const ModelFromFromGeometry = (props:TModelFromFromGeometryProps) =>{
    
    const {
        modelsGeometry,
        modelType,
        stepIndex,
    } = props

    if(modelsGeometry.upperSteps.length > 0 && modelsGeometry.lowerSteps.length > 0){
        
        return(
            <>
                {
                    modelType === 'TEETHS' &&
                    <>
                        <Teeths 
                            modelName={ 'teeths-upper' }
                            modelData={ [modelsGeometry.upperSteps[stepIndex]] }
                        />
                        <Teeths 
                            modelName={ 'teeths-lower' }
                            modelData={ [modelsGeometry.lowerSteps[stepIndex]] }
                        />
                    </>
                }

                {
                    modelType === 'GINGIVA' &&
                    <>
                        <Gingiva 
                            modelName={ 'gingiva-upper' }
                            modelData={ [modelsGeometry.upperSteps[stepIndex]] }
                        />
                        <Gingiva 
                            modelName={ 'gingiva-lower' }
                            modelData={ [modelsGeometry.lowerSteps[stepIndex]] }
                        /> 
                    </>
                }

            </>
        )
    }
    return <></>
}

export default ModelFromFromGeometry
