const isMobile = () =>{
    let hasTouchScreen = false;
    if ("maxTouchPoints" in navigator) {
        hasTouchScreen = navigator.maxTouchPoints > 0
    } else {
        const mediaQuery = window.matchMedia && matchMedia("(pointer:coarse)")
        if (mediaQuery && mediaQuery.media === "(pointer:coarse)") {
            hasTouchScreen = !!mediaQuery.matches;
        } else if ("orientation" in window) {
            hasTouchScreen = true // deprecated, but good fallback
        } else {
            // Only as a last resort, fall back to user agent sniffing
            var UserAgent = navigator.userAgent
            hasTouchScreen =
            /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UserAgent) ||
            /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UserAgent)
        }
    }
    return( hasTouchScreen ? true : false )
}

export default isMobile
